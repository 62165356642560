import React from 'react';
import {
  Grid, Card, CardContent, Skeleton,
} from '@mui/material';

import '../../App.css';

class MarginExpansion extends React.Component {
  render() {
    return (
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we expanding our revenue opportunities at META?</h5>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <img src="imgs/image1.jpg" className="image-width-100" alt="image1" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Description</h5>
              <p> A single page that provides high-level indicators and insights on profitability and profitability drivers.</p>
              <h5>Purpose</h5>
              <p>
                {' '}
                Provide leadership with the key indicators for measuring current and historical profitability and profitability drivers with the
                goal of identifying opportunities for expanding margins.

              </p>
              <h5>Scope</h5>
              <p>Account-wide with ability to see detail at the portfolio level down to the program level and by location.</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we expanding our delivery margins?  Are we hitting our targets?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <p>CCI $ and % over time and against current targets</p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we increasing margins on sales?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p>CCI % of New Sales, Extensions over time</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we managing our bench effectively?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p>Productivity on Max Bill Contracts</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we controlling our costs?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p>MOM, QOQ, YOY growth of payroll, non-payroll, and capitol costs</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default MarginExpansion;
